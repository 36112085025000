import React, { FC } from "react";
import { Slide } from "pure-react-carousel";
import * as styles from "../OurCustomersMainSlider.module.scss";
type Props = {
  sliderImage: any;
  sliderIndex: number;
};

const OurCustomersSlider: FC<Props> = ({ sliderImage, sliderIndex }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          justifyContent: "space-evenly",
          display: "flex",
          flexDirection: "column",
        }}
        className={`${styles.carouselMainDiv}`}
      >
        {sliderImage?.map((item: any, index: number) => (
          <Slide
            index={sliderIndex}
            id="slide"
            className={`${
              index ? styles.subDiv : styles.evenSlides
            } our_customers_slider`}
            key={index}
          >
            <div className={styles.carouselImageDiv} style={{ width: "100%" }}>
              <img
                src={item}
                className={styles.carouselImage}
                alt={"our customer "}
              />
            </div>
          </Slide>
        ))}
      </div>
    </>
  );
};

export default OurCustomersSlider;
