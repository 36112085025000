import React from "react";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import yearsAbout from "../../../images/mobile-images/yearsAbout.webp";
import aboutAws from "../../../images/mobile-images/aboutAws.webp";
import * as styles from "./AboutMain.module.scss";
import AboutParallaxContainer from "./AboutParallaxItem/AboutParallaxContainer";
import { aboutAwsDescription } from "./AboutMain.helper";
import TitleForSections from "../../Shared/TitleForSection/TitleForSections";
import translate from "../../../i18n/translate";

const AboutMain = () => {
  return (
    <div className={styles.aboutMainContainer}>
      <TitleForSections
        headerClassName={styles.aboutHeader}
        title={translate("home-whoweare")}
        headerWidth={"100%"}
      />
      <div className={styles.aboutMainContent}>
        <AboutParallaxContainer
          aboutSectionImage={yearsAbout}
          classNameParallaxContainer={styles.aboutUsYears}
          aboutUsDescriptionClass={styles.aboutUsDescription}
          orderDescription={2}
          orderImage={1}
          aboutDescription={translate("home-yearsDescription")}
          aboutTitle={translate("home-4years")}
          alt="Winding road in nature as representation for globaldatanet universal cloud knowledge"
        />

        <AboutParallaxContainer
          aboutSectionImage={aboutAws}
          classNameParallaxContainer={styles.aboutUsAws}
          aboutUsDescriptionClass={styles.aboutUsDescription}
          orderDescription={1}
          orderImage={2}
          aboutDescription={translate("home-aboutAws")}
          aboutTitle={aboutAwsDescription.title}
          alt="Foggy road during fall with AWS logo as transition between fog and clear forest"
        />
      </div>

      <div className={styles.backgroundCircles}>
        <CircleBlur
          initialOpacity={0}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.aboutMainCircleBlurGreen}
        />

        <CircleBlur
          initialOpacity={0}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.aboutMainCircleBlurBlue}
        />

        <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
      </div>
    </div>
  );
};

export default AboutMain;
