import {
  serviceAutomate,
  serviceDevelopment,
  serviceOptimize,
} from "../../HomeUiComponents/ServicesMain/ServicesMain.helper";
export const serviceTitleData = ({ startPoint, scrollPosition }: any) => {
  const isDevelopment = scrollPosition < startPoint + 0.17;

  const isOptimize =
    scrollPosition > startPoint + 0.17 && scrollPosition < startPoint + 0.34;

  const isAutomate =
    scrollPosition > startPoint + 0.34 && scrollPosition < startPoint + 2.52;

  if (isDevelopment)
    return {
      ...serviceDevelopment,
      animationStart: 0.05,
      animationEnd: 0.12,
    };
  if (isOptimize)
    return {
      ...serviceOptimize,
      animationStart: 0.22,
      animationEnd: 0.29,
    };
  if (isAutomate)
    return {
      ...serviceAutomate,
      animationStart: 0.39,
      animationEnd: 0.46,
    };
};
