import React, { FC } from "react";
import { UseWindowSize } from "../hooks/UseWindowSize";
import { ModalProps } from "../Shared/MobileModal/MobileModal";
import AboutMain from "./AboutMain/AboutMain";
import BlogMain from "./BlogMain/BlogMain";
import CaseStudiesMain from "./CaseStudiesMain/CaseStudiesMain";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HomeMobile from "./HomeMobileComponents/HomeMobile";
import ServicesMain from "./ServicesMain/ServicesMain";
import OurCustomersMain from "./OurCustomersMain/OurCustomersMain";
import FooterBanner from "../Shared/FooterBanner/FooterBanner";
import homeBackground from "../../images/homeBackground.jpg";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import * as styles from "./HomeMain.module.scss";
import SectionWrapper from "../Shared/SectionWrapper/SectionWrapper";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import TestimonialMain from "./TestimonialsMain/TestimonialMain";

type Props = {
  data: any;
  testimonialindexmobile: number;
  testimonialindex: number;
};

const HomeMain: FC<Props & ModalProps> = ({
  data,
  isModalHidden,
  setIsModalHidden,
  modalContent,
  testimonialindexmobile,
  testimonialindex,
}) => {
  const width = UseWindowSize();
  return (
    <>
      {width.width <= 770 && (
        <HomeMobile
          data={data}
          isModalHidden={isModalHidden}
          setIsModalHidden={setIsModalHidden}
          modalContent={modalContent}
          testimonialindex={testimonialindexmobile}
        />
      )}

      <section style={{ display: width.width > 770 ? "" : "none" }}>
        {width.width < 1200 ? (
          <HeroBannerMobile
            homeBackground={homeBackground}
            heading={translate("home-intro")}
            description={translate("home-description")}
            link={"/contact"}
            alt="Cliffs mountains beach and ocean for global digital transformation"
            buttonText={translate("home-contactus")}
          />
        ) : (
          <div className={styles.heading}>
            <HeroBanner
              heading={translate("home-intro")}
              description={translate("home-description")}
              buttonText={translate("home-contactus")}
              link={"/contact"}
              homeBackground={homeBackground}
            />
          </div>
        )}

        <AboutMain />
        <ServicesMain />
        <CircleBlur
          initialScale={0.1}
          initialOpacity={0}
          animateScale={1.8}
          animateOpacity={0.3}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.HomeMainCircleBlurGreen}
        />
        <OurCustomersMain />
        <TestimonialMain
          data={data.allSanityTestimonial}
          testimonialindex={testimonialindex}
        />
        <SectionWrapper>
          <CaseStudiesMain data={data.allSanityCaseStudy} />
        </SectionWrapper>
        <div className={styles.homeBlogWrapper}>
          <BlogMain data={data} />
        </div>
        <FooterBanner
          bottomText={translate("home-stayTuned")}
          bottomParagraph={translate("home-subscribe")}
          FormProp
        />
      </section>
    </>
  );
};

export default HomeMain;
