import translate from "../../../i18n/translate";

export const titlesAndDescription = [
  {
    titles: [
      { title: ".Development" },
      { title: ".Development" },
      { title: ".Development" },
      { title: ".Development" },
    ],
    description: [
      {
        section: ` To succeed in fast-paced, software-driven markets, companies must change the way they design, build, and use applications. Cloud-native application development is an approach to building, running, and improving apps based on well-known techniques and technologies for cloud computing. Cloud-native app development typically includes devops, agile methodology, microservices, cloud platforms, containers, serverless and continuous delivery —in short, every new and modern method of application deployment.`,
      },
      {
        section: `Improved collaboration between your development and operations
        teams contributes greatly to the reduction of time required for
        software building and bug fixing.`,
      },
    ],
  },
];

export const serviceDevelopment = {
  serviceName: translate("home-ourService"),
  title: translate("home-development"),
  link: "development",
  nextArticleTitle: translate("home-optimize"),
  lastArticleTitle: translate("home-automate"),
  nextArticle: "02",
  lastArticle: "03",
  description: translate("home-developmentDescription"),
};

export const serviceOptimize = {
  serviceName: translate("home-ourService2"),
  title: translate("home-optimize"),
  link: "optimize",
  nextArticleTitle: translate("home-development"),
  lastArticleTitle: translate("home-automate"),
  nextArticle: "01",
  lastArticle: "03",
  description: translate("home-optimizeDescription"),
};

export const serviceAutomate = {
  serviceName: translate("home-ourService3"),
  title: translate("home-automate"),
  link: "automate",
  nextArticleTitle: translate("home-development"),
  lastArticleTitle: translate("home-optimize"),
  nextArticle: "01",
  lastArticle: "02",
  description: translate("home-automateDescription"),
};
