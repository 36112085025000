import { motion } from "framer-motion";
import React, { FC } from "react";
import * as styles from "./ServicesTitle.module.scss";

type Props = {
  title: string;
  scrollPosition: number;
  animationStart: number;
  animationEnd: number;
  isFirstElement: boolean;
  yAxisStart: string;
};

const ServicesTitle: FC<Props> = ({
  title,
  scrollPosition,
  animationStart,
  animationEnd,
  isFirstElement,
  yAxisStart,
}) => {
  return (
    <div className={styles.titleWrapper}>
      <motion.h2
        initial={{ y: yAxisStart }}
        animate={{
          y: isFirstElement
            ? "0"
            : scrollPosition <= animationEnd && scrollPosition >= animationStart
            ? "0px"
            : yAxisStart,
        }}
        transition={{ duration: 1.1 }}
      >
        <span style={{ color: "white", fontSize: "80px" }}>.</span>
        {title}
      </motion.h2>
    </div>
  );
};

export default ServicesTitle;
