// extracted by mini-css-extract-plugin
export var articleBody = "HomeServicesMobile-module--articleBody--s7UTs";
export var backgroundCircles = "HomeServicesMobile-module--backgroundCircles--bPOe4";
export var heroBackgroundCircleLeft = "HomeServicesMobile-module--heroBackgroundCircleLeft--ne7XV";
export var heroBackgroundInnerCircleLeft = "HomeServicesMobile-module--heroBackgroundInnerCircleLeft--DxfC+";
export var heroWhiteBlurCirce = "HomeServicesMobile-module--heroWhiteBlurCirce--zAeO+";
export var nextArticle = "HomeServicesMobile-module--nextArticle--w5FnZ";
export var nextArticleContainer = "HomeServicesMobile-module--nextArticleContainer--pgeXa";
export var nextArticleText = "HomeServicesMobile-module--nextArticleText--tW9rk";
export var numberAndName = "HomeServicesMobile-module--numberAndName--3WNdO";
export var readMore = "HomeServicesMobile-module--readMore--Afbtm";
export var serviceArticle = "HomeServicesMobile-module--serviceArticle--msl-Y";
export var servicesMobileWrapper = "HomeServicesMobile-module--servicesMobileWrapper--Tkhgg";
export var sliderMain = "HomeServicesMobile-module--sliderMain--Hero5";