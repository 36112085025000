import React, { FC, useState } from "react";
import Slider from "react-slick";
import * as styles from "./HomeServicesMobile.module.scss";
import arrowGreenDown from "../../../../images/arrowGreenDown.svg";
import arrowGreenUp from "../../../../images/arrowGreenUp.svg";
import CircleOutline from "../../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../../../Shared/CircleOutlineAndBlur/CircleBlur";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../../../images/arrowRightWhite.svg";
import { serviceHelper } from "./HomeServiceMobile.helper";
import translate from "../../../../i18n/translate";

const HomeServicesMobile: FC = () => {
  const [sliderIndex, setSliderIndex] = useState(0);

  const checkTheServiceNumber = (serviceNumber: number) => {
    if (serviceNumber === 0 || serviceNumber === 3) {
      return 1;
    }
    if (serviceNumber === 1) {
      return 2;
    }
    if (serviceNumber === 2) {
      return 0;
    }
  };

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    afterChange: (currentSlide: any) => {
      setSliderIndex(currentSlide);
    },
    nextArrow: <img src={arrowGreenDown} alt="" id="next" />,
    prevArrow: <img src={arrowGreenUp} alt="" id="prev" />,
  };
  return (
    <div className={styles.servicesMobileWrapper}>
      <div className={styles.sliderMain}>
        <Slider {...settings}>
          {serviceHelper.map(el => {
            return (
              <div className={styles.serviceArticle} key={el.numberOfService}>
                <div className={styles.numberAndName}>
                  <span>{el.numberOfService}</span>
                  <span>{translate("home-ourServiceGeneral")}</span>
                </div>
                <div className={styles.articleBody}>
                  <h3>{el?.serviceTitle}</h3>
                  <p>{el?.serviceDescription}</p>
                  <CustomButton
                    text="Read more"
                    linkPath={el?.servicePath}
                    image={arrowRightWhite}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
        <div className={styles.nextArticleContainer}>
          <div className={styles.nextArticle}>
            <span>
              {
                serviceHelper[checkTheServiceNumber(sliderIndex)]
                  .numberOfService
              }
            </span>
            <span className={styles.nextArticleText}>
              {serviceHelper[checkTheServiceNumber(sliderIndex)].serviceTitle}
            </span>
          </div>

          <div className={styles.nextArticle}>
            <span>
              {
                serviceHelper[checkTheServiceNumber(sliderIndex + 1)]
                  .numberOfService
              }
            </span>
            <span className={styles.nextArticleText}>
              {
                serviceHelper[checkTheServiceNumber(sliderIndex + 1)]
                  .serviceTitle
              }
            </span>
          </div>
        </div>
      </div>
      <div className={styles.backgroundCircles}>
        <CircleOutline
          isBlurVisible={true}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
        <CircleBlur
          initialScale={0.1}
          animateScale={1}
          initialOpacity={0}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroWhiteBlurCirce}
        />
      </div>
    </div>
  );
};

export default HomeServicesMobile;
