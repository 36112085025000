import translate from "../../../../i18n/translate";
export const serviceHelper = [
  {
    numberOfService: "01",
    serviceTitle: translate("home-development"),
    serviceDescription: translate("home-developmentDescription"),
    servicePath: "/development",
  },
  {
    numberOfService: "02",
    serviceTitle: translate("home-optimize"),
    serviceDescription: translate("home-optimizeDescription"),
    servicePath: "/optimize",
  },
  {
    numberOfService: "03",
    serviceTitle: translate("home-automate"),
    serviceDescription: translate("home-automateDescription"),
    servicePath: "/automate",
  },
];
