// extracted by mini-css-extract-plugin
export var btnMargin = "OurCustomersMain-module--btnMargin--9Pmk8";
export var carousel = "OurCustomersMain-module--carousel--vGIR8";
export var carouselContainer = "OurCustomersMain-module--carouselContainer--POrbV";
export var carouselMainContainer = "OurCustomersMain-module--carouselMainContainer--KLWWk";
export var caseStudiesDescription = "OurCustomersMain-module--caseStudiesDescription--Jmz--";
export var caseStudiesHeader = "OurCustomersMain-module--caseStudiesHeader---+Dlj";
export var customerHeading = "OurCustomersMain-module--customerHeading--1-uUw";
export var customerSubDiv = "OurCustomersMain-module--customerSubDiv--iRS3T";
export var customers = "OurCustomersMain-module--customers--X3Brk";
export var evenSlides = "OurCustomersMain-module--evenSlides--v-sNu";
export var heroBackgroundCircleLeft = "OurCustomersMain-module--heroBackgroundCircleLeft--6jYU0";
export var heroBackgroundInnerCircleLeft = "OurCustomersMain-module--heroBackgroundInnerCircleLeft--P57Us";
export var sliderBtns = "OurCustomersMain-module--sliderBtns--DPgdD";
export var sliderComp = "OurCustomersMain-module--sliderComp--eyDL+";
export var subDiv = "OurCustomersMain-module--subDiv--FMndd";