// extracted by mini-css-extract-plugin
export var ContentBlock = "TestimonialMain-module--ContentBlock--fRWD8";
export var ImageBox = "TestimonialMain-module--ImageBox--CRn0P";
export var ImageBoxForth = "TestimonialMain-module--ImageBoxForth--zCmOf";
export var ImageBoxSecond = "TestimonialMain-module--ImageBoxSecond--cHx2s";
export var TextBox = "TestimonialMain-module--TextBox--KtAwe";
export var TextBoxLast = "TestimonialMain-module--TextBoxLast--kvEdF";
export var application = "TestimonialMain-module--application--1mmqq";
export var applicationHeading = "TestimonialMain-module--applicationHeading--Oe-Di";
export var applicationMap = "TestimonialMain-module--applicationMap--nDMFE";
export var approach = "TestimonialMain-module--approach--F5wG5";
export var backgroundCircles = "TestimonialMain-module--backgroundCircles--HyI+K";
export var banner = "TestimonialMain-module--banner--Xco2C";
export var benefits = "TestimonialMain-module--benefits--z-ctb";
export var bottomButton = "TestimonialMain-module--bottomButton--UAC3D";
export var careerBtnApplyNow = "TestimonialMain-module--careerBtnApplyNow--z5Ppm";
export var careers = "TestimonialMain-module--careers--H4HZm";
export var caseChallengeImage = "TestimonialMain-module--caseChallengeImage--AF2WJ";
export var caseTestimonialImage = "TestimonialMain-module--caseTestimonialImage--Bkovo";
export var contact = "TestimonialMain-module--contact--sx34g";
export var contactButton = "TestimonialMain-module--contactButton--JA6K1";
export var contactOutlineCircleBenfits = "TestimonialMain-module--contactOutlineCircleBenfits--5-SbF";
export var coreValue = "TestimonialMain-module--coreValue--3fK31";
export var coreValueColumbLeft = "TestimonialMain-module--coreValueColumbLeft--bX6VM";
export var coreValueColumbRight = "TestimonialMain-module--coreValueColumbRight--lc+VR";
export var coreValueItem = "TestimonialMain-module--coreValueItem--+15XS";
export var coreValueItemBox = "TestimonialMain-module--coreValueItemBox--2wfsY";
export var flowContainer = "TestimonialMain-module--flowContainer--RV9Tj";
export var flowItem = "TestimonialMain-module--flowItem--gCSDM";
export var flowItemDay = "TestimonialMain-module--flowItemDay--KJnNI";
export var flowItemDayMobile = "TestimonialMain-module--flowItemDayMobile--u88N3";
export var flowItemDayMobileContainer = "TestimonialMain-module--flowItemDayMobileContainer--WeTXD";
export var flowItemMobile = "TestimonialMain-module--flowItemMobile--ADeWJ";
export var flowLeftContainer = "TestimonialMain-module--flowLeftContainer--v6eDb";
export var flowRightContainer = "TestimonialMain-module--flowRightContainer--HT6+d";
export var flowRightContainerMobile = "TestimonialMain-module--flowRightContainerMobile--ltE6s";
export var flowStop = "TestimonialMain-module--flowStop--3sSrw";
export var followUs = "TestimonialMain-module--followUs--vE-WG";
export var gdnPromotePic = "TestimonialMain-module--gdnPromotePic--rGstA";
export var heading = "TestimonialMain-module--heading--yzsDY";
export var heroBackgroundCircleLeft = "TestimonialMain-module--heroBackgroundCircleLeft--Zb6jH";
export var heroBackgroundInnerCircleGreen = "TestimonialMain-module--heroBackgroundInnerCircleGreen--CsUMv";
export var heroBackgroundInnerCircleLeft = "TestimonialMain-module--heroBackgroundInnerCircleLeft--gqiUl";
export var heroBackgroundInnerCircleLeftBenfits = "TestimonialMain-module--heroBackgroundInnerCircleLeftBenfits--3CVEQ";
export var heroBannerMainContainer = "TestimonialMain-module--heroBannerMainContainer--nSeEG";
export var heroWhiteBlurCirce = "TestimonialMain-module--heroWhiteBlurCirce--qzVly";
export var igImageGroup1 = "TestimonialMain-module--igImageGroup1--z75of";
export var igImageGroup1Item = "TestimonialMain-module--igImageGroup1Item--+LH3L";
export var igImageGroup2 = "TestimonialMain-module--igImageGroup2--7cZlX";
export var igImageGroup2Btm = "TestimonialMain-module--igImageGroup2Btm--Ji5lV";
export var igImageGroup2BtmItem = "TestimonialMain-module--igImageGroup2BtmItem--YOJ7s";
export var igImageGroup2Item = "TestimonialMain-module--igImageGroup2Item--UZD2w";
export var inNumberColumbLeft = "TestimonialMain-module--inNumberColumbLeft--uhs-b";
export var inNumberColumbRight = "TestimonialMain-module--inNumberColumbRight--7lLm9";
export var inNumbersImage = "TestimonialMain-module--inNumbersImage--3AOOh";
export var inNumbersItem = "TestimonialMain-module--inNumbersItem--wXiDz";
export var innumbers = "TestimonialMain-module--innumbers--au4A-";
export var insights = "TestimonialMain-module--insights--MHNBL";
export var instaContainer = "TestimonialMain-module--instaContainer--FFCRD";
export var instaLeftBox = "TestimonialMain-module--instaLeftBox--MdjbU";
export var instaRightBox = "TestimonialMain-module--instaRightBox--9KFYY";
export var introDescriptionButtonSection = "TestimonialMain-module--introDescriptionButtonSection--xFVv0";
export var introImageSection = "TestimonialMain-module--introImageSection--ELewx";
export var introSectionCircleItem = "TestimonialMain-module--introSectionCircleItem--8luUX";
export var introSectionContainer = "TestimonialMain-module--introSectionContainer--eYMVN";
export var introSectionText = "TestimonialMain-module--introSectionText--Z7fYV";
export var keyTopicsImage = "TestimonialMain-module--keyTopicsImage--GLMEQ";
export var knowUs = "TestimonialMain-module--knowUs--T2DdE";
export var knowUsImage = "TestimonialMain-module--knowUsImage--rZGJw";
export var knowUsText = "TestimonialMain-module--knowUsText--drZ8l";
export var pillars = "TestimonialMain-module--pillars--GRM9A";
export var reportBtn = "TestimonialMain-module--reportBtn--jDFcT";
export var reportBtnGerman = "TestimonialMain-module--reportBtnGerman--3wGC3";
export var singleCaseTestimonial = "TestimonialMain-module--singleCaseTestimonial--31or4";
export var singleCaseTestimonialInner = "TestimonialMain-module--singleCaseTestimonialInner--70KuK";
export var sliderItem = "TestimonialMain-module--sliderItem--snSh5";
export var testimonialBody = "TestimonialMain-module--testimonialBody--zONdj";
export var testimonialButtonsContainer = "TestimonialMain-module--testimonialButtonsContainer--39SW1";
export var testimonialContent = "TestimonialMain-module--testimonialContent--EWUoJ";
export var testimonialMainContainer = "TestimonialMain-module--testimonialMainContainer--yW-QJ";
export var testimonialName = "TestimonialMain-module--testimonialName--zyb5N";
export var testimonialSliderContainer = "TestimonialMain-module--testimonialSliderContainer--Dq-i2";
export var torchWrapper = "TestimonialMain-module--torchWrapper--LXv9A";
export var verticalLine = "TestimonialMain-module--verticalLine--1UidS";
export var verticalLineMobile = "TestimonialMain-module--verticalLineMobile--fqAMe";
export var yourBenefits = "TestimonialMain-module--yourBenefits--+GvsA";
export var yourBenefitsIcon = "TestimonialMain-module--yourBenefitsIcon--71QpZ";
export var yourBenefitsInner = "TestimonialMain-module--yourBenefitsInner--6NJDh";
export var yourBenefitsItem = "TestimonialMain-module--yourBenefitsItem--unzjC";