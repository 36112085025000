import React, { FC, useRef } from "react";
import loadable from "@loadable/component";
import * as styles from "./HomeBlogMobile.module.scss";
import greenArrowRightBlog from "../../../../images/greenArrowRightBlog.svg";
import arrowGreenLeftBlog from "../../../../images/arrowGreenLeftBlog.svg";
import { UseImageUrl } from "../../../hooks/UseImageUrl";
import { Link } from "gatsby";
import { checkUrl } from "../../../Shared/HelperFunctions/HelperFunctions";
import moment from "moment";
import { ImageUrlCheck } from "../../../Shared/ImageUrlCheck/ImageUrlCheck";

const Carousel = loadable(() => import("react-tiny-slider"));

type Props = {
  data: any;
};

const HomeBlogMobile: FC<Props> = ({ data }) => {
  const carouselBlog = useRef(null);

  const goNextSlide = () => carouselBlog.current.goTo("next");
  const goPreviousSlide = () => carouselBlog.current.goTo("prev");
  return (
    <div className={styles.blogMobileWrapper}>
      <div style={{ zIndex: 5, position: "relative" }}>
        <div className={styles.blogHeader}>
          <h2 className="heading">
            <span>.</span>Blog
          </h2>
          <div className={styles.blogArrowContainer}>
            <img
              src={arrowGreenLeftBlog}
              onClick={() => goPreviousSlide()}
              alt=""
            />
            <img
              src={greenArrowRightBlog}
              onClick={() => goNextSlide()}
              alt=""
            />
          </div>
        </div>
        <Carousel
          swipeAngle={false}
          items={1}
          mouseDrag
          center={true}
          loop={true}
          gutter={15}
          ref={carouselBlog}
          controls={false}
          nav={false}
          style={{ overflow: "hidden" }}
        >
          {data.edges.map(({ node }: any, index: number) => {
            UseImageUrl(node.mainImage, node._rawMainImage);

            return (
              <div key={index}>
                <Link to={`/tech-blog/${checkUrl(node?.title)}`}>
                  <div className={styles.blogImageContainer}>
                    <img src={ImageUrlCheck(node._rawMainImage)} alt="" />
                  </div>
                  <div className={styles.blogContent}>
                    <div className={styles.blogDate}>
                      <span>
                        {moment(node.publishedAt).format("MMM Do YYYY")}
                      </span>
                      <img
                        src={greenArrowRightBlog}
                        onClick={() => goNextSlide()}
                        alt=""
                      />
                    </div>
                    <h3 className="heading">
                      <span>.</span>
                      {node.title}
                    </h3>
                    <p>{node.subtitle}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeBlogMobile;
