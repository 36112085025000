import React, { FC, useState } from "react";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import HomeMain from "../components/HomeUiComponents/HomeMain";
import { graphql } from "gatsby";
import homeimage from "../images/homeBackground.jpg";

type Props = {
  data: any;
  location: any;
};
function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const IndexPage: FC<Props> = ({ location, data }) => {
  const [isModalHidden, setIsModalHidden] = useState<boolean>(false);
  const testimonialindexmobile = randomIntFromInterval(
    0,
    data.allSanityTestimonial.edges.length - 1
  );
  const testimonialindex = randomIntFromInterval(
    0,
    data.allSanityTestimonial.edges.length - 1
  );
  return (
    <Layout
      setIsModalHidden={setIsModalHidden}
      isModalHidden={isModalHidden}
      location={location.pathname}
    >
      <>
        <SEO
          title=".More business .Made in the Cloud - globaldatanet"
          description="globaldatanet is a leading cloud-native company with the mission to build and optimize next-generation applications for the cloud. We are an AWS Advanced Consulting Partner."
          georegion="DE-HH"
          geoposition="53.582670;10.048710"
          geoplacename="Alter Teichweg 11, 22081 Hamburg, Deutschland"
          robots="index, follow"
          type="website"
          image={homeimage}
        />

        <HomeMain
          isModalHidden={isModalHidden}
          setIsModalHidden={setIsModalHidden}
          modalContent={"skr skr"}
          data={data}
          testimonialindex={testimonialindex}
          testimonialindexmobile={testimonialindexmobile}
        />
      </>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query AllSanityBlogIndexQuery {
    allSanityBlog(
      limit: 5
      filter: { blogType: { elemMatch: { title: { eq: "tech-blog" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
          publishedAt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allSanityCaseStudy(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          title
          titleDE
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          altMainImage
          altAboutImage
          altSolutionImage
          altBenefitsImage
          altChallengeImage
          altTestimonialImage
          caseStudyDescription {
            children {
              text
            }
          }
          caseStudyDescriptionDe {
            children {
              text
            }
          }
        }
      }
    }
    allSanityTag {
      nodes {
        title
      }
    }
    allSanityTestimonial {
      edges {
        node {
          description {
            _rawChildren
          }
          descriptionDe {
            _rawChildren
          }
          name
          position
          _rawPicture(resolveReferences: { maxDepth: 20 })
          picture {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
