// extracted by mini-css-extract-plugin
export var ContentBlock = "TestimonialMain-module--ContentBlock--qAjDK";
export var ImageBox = "TestimonialMain-module--ImageBox--rvL1l";
export var ImageBoxForth = "TestimonialMain-module--ImageBoxForth--CTb+r";
export var ImageBoxSecond = "TestimonialMain-module--ImageBoxSecond--G71+6";
export var TextBox = "TestimonialMain-module--TextBox--q2nK5";
export var TextBoxLast = "TestimonialMain-module--TextBoxLast--LhYhf";
export var application = "TestimonialMain-module--application--a4GeZ";
export var applicationHeading = "TestimonialMain-module--applicationHeading--63eym";
export var applicationMap = "TestimonialMain-module--applicationMap--fjZf5";
export var approach = "TestimonialMain-module--approach--5Jlqr";
export var backgroundCircles = "TestimonialMain-module--backgroundCircles--5nQbR";
export var banner = "TestimonialMain-module--banner--xgeje";
export var benefits = "TestimonialMain-module--benefits--Q6Mnb";
export var bottomButton = "TestimonialMain-module--bottomButton--X8MZD";
export var careerBtnApplyNow = "TestimonialMain-module--careerBtnApplyNow--5N5jt";
export var careers = "TestimonialMain-module--careers--SvrcI";
export var caseChallengeImage = "TestimonialMain-module--caseChallengeImage--sd65D";
export var caseTestimonialImage = "TestimonialMain-module--caseTestimonialImage--Asa04";
export var contact = "TestimonialMain-module--contact--6X+X3";
export var contactButton = "TestimonialMain-module--contactButton--EJEhm";
export var contactOutlineCircleBenfits = "TestimonialMain-module--contactOutlineCircleBenfits--8EwAO";
export var coreValue = "TestimonialMain-module--coreValue--u45n1";
export var coreValueColumbLeft = "TestimonialMain-module--coreValueColumbLeft--hL71O";
export var coreValueColumbRight = "TestimonialMain-module--coreValueColumbRight--jlqP7";
export var coreValueItem = "TestimonialMain-module--coreValueItem--ZtKhM";
export var coreValueItemBox = "TestimonialMain-module--coreValueItemBox--JoxjS";
export var flowContainer = "TestimonialMain-module--flowContainer--JKfp8";
export var flowItem = "TestimonialMain-module--flowItem--F3UrW";
export var flowItemDay = "TestimonialMain-module--flowItemDay--EEl46";
export var flowItemDayMobile = "TestimonialMain-module--flowItemDayMobile--hNPC2";
export var flowItemDayMobileContainer = "TestimonialMain-module--flowItemDayMobileContainer--r-RNZ";
export var flowItemMobile = "TestimonialMain-module--flowItemMobile----8NH";
export var flowLeftContainer = "TestimonialMain-module--flowLeftContainer--J9+Uu";
export var flowRightContainer = "TestimonialMain-module--flowRightContainer--i8CFg";
export var flowRightContainerMobile = "TestimonialMain-module--flowRightContainerMobile--YIVQL";
export var flowStop = "TestimonialMain-module--flowStop--RUvnz";
export var followUs = "TestimonialMain-module--followUs--JbD9s";
export var gdnPromotePic = "TestimonialMain-module--gdnPromotePic--vf3y5";
export var heading = "TestimonialMain-module--heading--j-LFe";
export var heroBackgroundCircleLeft = "TestimonialMain-module--heroBackgroundCircleLeft--eWT2+";
export var heroBackgroundInnerCircleGreen = "TestimonialMain-module--heroBackgroundInnerCircleGreen--o3XiU";
export var heroBackgroundInnerCircleLeft = "TestimonialMain-module--heroBackgroundInnerCircleLeft--fdV0E";
export var heroBackgroundInnerCircleLeftBenfits = "TestimonialMain-module--heroBackgroundInnerCircleLeftBenfits--4BnJN";
export var heroBannerMainContainer = "TestimonialMain-module--heroBannerMainContainer--dNmRC";
export var heroWhiteBlurCirce = "TestimonialMain-module--heroWhiteBlurCirce--rnAw0";
export var igImageGroup1 = "TestimonialMain-module--igImageGroup1--N2VgB";
export var igImageGroup1Item = "TestimonialMain-module--igImageGroup1Item--ljjbz";
export var igImageGroup2 = "TestimonialMain-module--igImageGroup2--qmoor";
export var igImageGroup2Btm = "TestimonialMain-module--igImageGroup2Btm--d0XLt";
export var igImageGroup2BtmItem = "TestimonialMain-module--igImageGroup2BtmItem---jf2O";
export var igImageGroup2Item = "TestimonialMain-module--igImageGroup2Item--Q0kht";
export var inNumberColumbLeft = "TestimonialMain-module--inNumberColumbLeft--1CGAl";
export var inNumberColumbRight = "TestimonialMain-module--inNumberColumbRight--O60NI";
export var inNumbersImage = "TestimonialMain-module--inNumbersImage--UYczx";
export var inNumbersItem = "TestimonialMain-module--inNumbersItem--zejSc";
export var innumbers = "TestimonialMain-module--innumbers--w0+se";
export var insights = "TestimonialMain-module--insights--CalmJ";
export var instaContainer = "TestimonialMain-module--instaContainer--4zIFo";
export var instaLeftBox = "TestimonialMain-module--instaLeftBox--wWIg+";
export var instaRightBox = "TestimonialMain-module--instaRightBox--M7ecg";
export var introDescriptionButtonSection = "TestimonialMain-module--introDescriptionButtonSection--hrbCx";
export var introImageSection = "TestimonialMain-module--introImageSection--IdkDi";
export var introSectionCircleItem = "TestimonialMain-module--introSectionCircleItem--N8DnL";
export var introSectionContainer = "TestimonialMain-module--introSectionContainer--eY22s";
export var introSectionText = "TestimonialMain-module--introSectionText--b+fkg";
export var keyTopicsImage = "TestimonialMain-module--keyTopicsImage--sl9cE";
export var knowUs = "TestimonialMain-module--knowUs--1svvd";
export var knowUsImage = "TestimonialMain-module--knowUsImage--xj2FC";
export var knowUsText = "TestimonialMain-module--knowUsText--h+AC0";
export var pillars = "TestimonialMain-module--pillars--HG6x8";
export var reportBtn = "TestimonialMain-module--reportBtn--n0B9L";
export var reportBtnGerman = "TestimonialMain-module--reportBtnGerman--D63g7";
export var singleCaseTestimonial = "TestimonialMain-module--singleCaseTestimonial--ysf-Y";
export var singleCaseTestimonialInner = "TestimonialMain-module--singleCaseTestimonialInner--P3gFS";
export var sliderItem = "TestimonialMain-module--sliderItem--YwUP3";
export var testimonialBody = "TestimonialMain-module--testimonialBody--VDd+l";
export var testimonialButtonsContainer = "TestimonialMain-module--testimonialButtonsContainer--kczbp";
export var testimonialContent = "TestimonialMain-module--testimonialContent--7y4jR";
export var testimonialMainContainer = "TestimonialMain-module--testimonialMainContainer--1UHJD";
export var testimonialName = "TestimonialMain-module--testimonialName--tqqS9";
export var testimonialSliderContainer = "TestimonialMain-module--testimonialSliderContainer--FkhZA";
export var torchWrapper = "TestimonialMain-module--torchWrapper--C9MmB";
export var verticalLine = "TestimonialMain-module--verticalLine--VpE2s";
export var verticalLineMobile = "TestimonialMain-module--verticalLineMobile--8HDr8";
export var yourBenefits = "TestimonialMain-module--yourBenefits--RiIyj";
export var yourBenefitsIcon = "TestimonialMain-module--yourBenefitsIcon--XSmaj";
export var yourBenefitsInner = "TestimonialMain-module--yourBenefitsInner--MkPFS";
export var yourBenefitsItem = "TestimonialMain-module--yourBenefitsItem--9laps";