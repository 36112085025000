import React, { FC } from "react";
import * as styles from "./OurCustomersMain.module.scss";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import AES from "../../../images/HomeMain-Images/AES.svg";
import Barmer from "../../../images/HomeMain-Images/Barmer.svg";
import HPV from "../../../images/HomeMain-Images/HPV.svg";
import Otto from "../../../images/HomeMain-Images/OTTO.svg";
import Korber from "../../../images/HomeMain-Images/Korber.svg";
import Adference from "../../../images/HomeMain-Images/Adference.svg";
import Bigpoint from "../../../images/HomeMain-Images/Bigpoint.svg";
import Glasswall from "../../../images/HomeMain-Images/Glasswall.svg";
import HapagLloyd from "../../../images/HomeMain-Images/Hapag Lloyd.svg";
import Vodafone from "../../../images/HomeMain-Images/Vodafone.svg";
import Gothaer from "../../../images/HomeMain-Images/Gothaer.svg";
import Audi from "../../../images/HomeMain-Images/Audi.svg";
import SE from "../../../images/HomeMain-Images/siemens_energy.svg";
import Ericsson from "../../../images/HomeMain-Images/ericsson.svg";
import Addidas from "../../../images/HomeMain-Images/addidas.svg";
import OurCustomersMobileSlider from "../../OptimizationUiComponents/Slider/OurCustomersMobileSlider";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";

const OurCustomersMobile: FC = () => {
  const customerSlider = [
    [Audi, Barmer],
    [Otto, HapagLloyd],
    [Addidas, Ericsson],
    [SE, Vodafone],
    [Bigpoint, HPV],
    [Gothaer, AES],
    [Glasswall, Korber],
    [Adference],
  ];
  return (
    <div className={styles.customers}>
      <InnerWrapper>
        <div className={styles.customerSubDiv}>
          <h2 className="heading">
            <span>.</span>Our Customer
          </h2>
          <div className={styles.carouselMainContainer}>
            <OurCustomersMobileSlider data={customerSlider} />
          </div>
        </div>
        <CircleBlur
          initialOpacity={0}
          animateOpacity={0.13}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={""}
        />
        <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
      </InnerWrapper>
    </div>
  );
};

export default OurCustomersMobile;
