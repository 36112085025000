type AboutUsItems = {
  title: string;
  description: string;
};

export const yearsDescription: AboutUsItems = {
  title: "4 years",
  description: `globaldatanet is a leading cloud-native company with the mission
    to build next-generation applications for the cloud. For more than
    4 years, globaldatanet has been helping other businesses adopt
    cutting-edge technologies to solve challenges and create new
    opportunities.`,
};

export const aboutAwsDescription: AboutUsItems = {
  title: "100% AWS",
  description: `AWS Advanced Consulting Partner with very strong relationship to AWS and community leadership.
   Our partnership has existed from the very beginning of our journey and is strengthened day by day. 
   We are one of few partners to be awarded with the AWS DevOps competency.`,
};
