// extracted by mini-css-extract-plugin
export var aboutHeader = "AboutMain-module--aboutHeader--atyGj";
export var aboutMainCircleBlurBlue = "AboutMain-module--aboutMainCircleBlurBlue--VOpnh";
export var aboutMainCircleBlurGreen = "AboutMain-module--aboutMainCircleBlurGreen--zUooL";
export var aboutMainCircleRightBlue = "AboutMain-module--aboutMainCircleRightBlue--GS5Y-";
export var aboutMainContainer = "AboutMain-module--aboutMainContainer--b6UAF";
export var aboutMainContent = "AboutMain-module--aboutMainContent--eW9VS";
export var aboutUsAws = "AboutMain-module--aboutUsAws--knyL6";
export var aboutUsDescription = "AboutMain-module--aboutUsDescription--M5RLy";
export var aboutUsYears = "AboutMain-module--aboutUsYears--s8qM1";
export var backgroundCircles = "AboutMain-module--backgroundCircles--Ibwqi";
export var heroBackgroundCircleLeft = "AboutMain-module--heroBackgroundCircleLeft--6Ck2+";
export var heroWhiteBlurCirce = "AboutMain-module--heroWhiteBlurCirce--DxImC";