import React, { FC, ReactElement } from "react";
import AboutParallaxItem from "./AboutParallaxItem";

type Props = {
  classNameParallaxContainer: string;
  aboutUsDescriptionClass: string;
  aboutSectionImage: string;
  aboutTitle: string | ReactElement;
  orderImage: number;
  orderDescription: number;
  aboutDescription: string | ReactElement;
  alt: string;
};

const AboutParallaxContainer: FC<Props> = ({
  classNameParallaxContainer,
  aboutUsDescriptionClass,
  aboutSectionImage,
  aboutTitle,
  aboutDescription,
  orderImage,
  orderDescription,
  alt,
}) => {
  return (
    <div className={classNameParallaxContainer}>
      <img
        className="image"
        loading="lazy"
        src={aboutSectionImage}
        alt={alt}
        style={{ order: orderImage }}
      />

      <div
        className={aboutUsDescriptionClass}
        style={{ order: orderDescription }}
      >
        <AboutParallaxItem>
          <>
            <h4 className="heading">
              <span>.</span>
              {aboutTitle}
            </h4>
            <p>{aboutDescription}</p>
          </>
        </AboutParallaxItem>
      </div>
    </div>
  );
};

export default AboutParallaxContainer;
