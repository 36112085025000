// extracted by mini-css-extract-plugin
export var aboutText = "HomeBlogMobile-module--aboutText--DVGK+";
export var backgroundCircles = "HomeBlogMobile-module--backgroundCircles--Vodna";
export var blogArrowContainer = "HomeBlogMobile-module--blogArrowContainer--oig3j";
export var blogContent = "HomeBlogMobile-module--blogContent--Y17lW";
export var blogDate = "HomeBlogMobile-module--blogDate--OFSVA";
export var blogHeader = "HomeBlogMobile-module--blogHeader--WXS9i";
export var blogImageContainer = "HomeBlogMobile-module--blogImageContainer--nZQg2";
export var blogMobileWrapper = "HomeBlogMobile-module--blogMobileWrapper--KE7Au";
export var contactButton = "HomeBlogMobile-module--contactButton--cZESP";
export var heroBackgroundCircleBottom = "HomeBlogMobile-module--heroBackgroundCircleBottom--+HY2R";
export var heroBackgroundCircleLeft = "HomeBlogMobile-module--heroBackgroundCircleLeft--l4t8Z";
export var heroBackgroundInnerCircleLeft = "HomeBlogMobile-module--heroBackgroundInnerCircleLeft--ra1qp";
export var heroWhiteBlurCirce = "HomeBlogMobile-module--heroWhiteBlurCirce--e7oqy";