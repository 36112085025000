// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OurCustomersMobileSlider-module--PartnerMobileCarousel--6hdUZ";
export var PartnersCarouselDesc = "OurCustomersMobileSlider-module--PartnersCarouselDesc--GsvdG";
export var blogArrowContainer = "OurCustomersMobileSlider-module--blogArrowContainer--Kl3v3";
export var carousel = "OurCustomersMobileSlider-module--carousel--kETcO";
export var carouselDiv = "OurCustomersMobileSlider-module--carouselDiv---DHV0";
export var carouselImage = "OurCustomersMobileSlider-module--carouselImage--RilPy";
export var carouselImageDiv = "OurCustomersMobileSlider-module--carouselImageDiv--Y-gBK";
export var carouselImg = "OurCustomersMobileSlider-module--carouselImg---pz+M";
export var carouselImgDiv = "OurCustomersMobileSlider-module--carouselImgDiv--XYDb0";
export var carouselInnerDiv = "OurCustomersMobileSlider-module--carouselInnerDiv--hEo4L";
export var carouselMainDiv = "OurCustomersMobileSlider-module--carouselMainDiv--dgoRo";
export var carouselSubDiv = "OurCustomersMobileSlider-module--carouselSubDiv--MjU6R";
export var carousel__container = "OurCustomersMobileSlider-module--carousel__container--brPbk";
export var carousel__slider = "OurCustomersMobileSlider-module--carousel__slider--6K-+6";
export var carousel__sliderTrayWrapHorizontal = "OurCustomersMobileSlider-module--carousel__slider-tray-wrap--horizontal--puFCs";
export var carousel__sliderTrayWrapper = "OurCustomersMobileSlider-module--carousel__slider-tray-wrapper--ct1qK";
export var caseStudiesDescription = "OurCustomersMobileSlider-module--caseStudiesDescription--vkg4-";
export var contactButton = "OurCustomersMobileSlider-module--contactButton--X6Rfv";
export var evenSlides = "OurCustomersMobileSlider-module--evenSlides--UVk1a";
export var partnersMainSlider = "OurCustomersMobileSlider-module--partnersMainSlider--2oVpS";
export var subDiv = "OurCustomersMobileSlider-module--subDiv--U8DJc";