import React, {
  useRef,
  useState,
  useLayoutEffect,
  ReactElement,
  FC,
} from "react";
import {
  useViewportScroll,
  useTransform,
  useSpring,
  motion,
} from "framer-motion";

const calculateMinHeight = (height: any, range: any) => {
  return height + height * range;
};
const rand = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (+max - +min)) + +min;
};

type Props = {
  children: ReactElement;
  classNameParallax?: string;
  topOffset?: number;
  bottomOffset?: number;
  range?: number;
};

const AboutParallaxItem: FC<Props> = ({
  children,
  classNameParallax,
  topOffset = -900,
  bottomOffset = 800,
  range = 0.4,
}) => {
  const { scrollY } = useViewportScroll();
  const ref = useRef(null);
  const [minHeight, setMinHeight] = useState<string | number>("auto");
  const [elementTop, setElementTop] = useState<number>(0);
  const springConfig = {
    damping: 100,
    stiffness: 100,
    mass: rand(1, 3),
  };

  useLayoutEffect(() => {
    if (!ref.current) return;
    const onResize = () => {
      setElementTop(ref.current.offsetTop);
      setMinHeight(calculateMinHeight(ref.current.offsetHeight, range));
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref, range]);

  const y = useSpring(
    useTransform(
      scrollY,
      [elementTop + topOffset, elementTop + bottomOffset],
      ["0%", `${range * 200}%`]
    ),
    springConfig
  );

  return (
    <div style={{ minHeight }} className={classNameParallax}>
      <motion.div ref={ref} initial={{ y: 0 }} style={{ y }}>
        {children}
      </motion.div>
    </div>
  );
};

export default AboutParallaxItem;
