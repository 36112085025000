import React, { FC, useEffect, useState } from "react";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import BlogArticle from "./BlogArticle/BlogArticle";
import * as styles from "./BlogMain.module.scss";
import Sticky from "react-sticky-el";
import BlogArticleImage from "./BlogArticle/BlogArticleImage";
import TitleForSections from "../../Shared/TitleForSection/TitleForSections";

type Props = {
  data: any;
};

const BlogMain: FC<Props> = ({ data }) => {
  const [showBlogImage, setShowBlogImage] = useState({
    image: "",
    pathToBlog: "",
    title: "",
    alt: "",
  });

  useEffect(() => {
    let firstTechBlogImage = data.allSanityBlog.edges.find(
      ({ node }: any) => node.blogType[0].title === "tech-blog"
    );

    setShowBlogImage({
      image: firstTechBlogImage?.node._rawMainImage,
      pathToBlog: "/tech-blog",
      title: firstTechBlogImage?.node.title || "",
      alt: firstTechBlogImage?.node.alt || "",
    });
  }, [data]);

  return (
    <div className={styles.blogMainContainer}>
      <div className={styles.stickyContainerLeft} id="stickyContainer">
        <Sticky boundaryElement="#stickyContainer">
          <div className={styles.backgroundCircles}>
            <CircleOutline
              isBlurVisible={false}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutBackgroundCircleLeft}
            />
          </div>
          <TitleForSections
            headerClassName={styles.blogHeader}
            title={"Blog"}
            headerWidth={"auto"}
          />

          <BlogArticleImage
            imageClassName={styles.blogImageContainer}
            imageName={"dsas"}
            rawImage={showBlogImage?.image}
            pathToBlog={showBlogImage?.pathToBlog}
            title={showBlogImage?.title}
            alt={showBlogImage?.alt}
          />
        </Sticky>
      </div>
      <div className={styles.blogArticlesWrapper}>
        <div className={styles.blogArticlesContainer}>
          {data.allSanityBlog.edges.map(({ node }: any, index: number) => {
            if (node.blogType[0].title === "tech-blog") {
              return (
                <BlogArticle
                  title={node.title}
                  description={node.subtitle}
                  dateBlog={node.publishedAt}
                  key={index}
                  pic={node._rawMainImage}
                  onBlogHover={setShowBlogImage}
                  pathToBlog={"/tech-blog"}
                  alt={node?.alt}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
