import React, { FC } from "react";
import ServicesTitle from "./ServicesTitle";
import * as styles from "../ServicesMain.module.scss";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../../../images/arrowRightWhite.svg";
import CircleBlur from "../../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../../Shared/CircleOutlineAndBlur/CircleOutline";
import translate from "../../../../i18n/translate";
type Props = {
  serviceName: string;
  serviceTitle: string;
  scrollPosition: number;
  animationStart: number;
  animationEnd: number;
  nextArticle: string;
  lastArticle: string;
  description: string;
  nextArticleTitle: string;
  lastArticleTitle: string;
  link: string;
};

const ServiceTitleWrapper: FC<Props> = ({
  serviceName,
  serviceTitle,
  scrollPosition,
  animationStart,
  animationEnd,
  nextArticle,
  lastArticle,
  description,
  nextArticleTitle,
  lastArticleTitle,
  link,
}) => {
  return (
    <div className={styles.servicesLeftAndRightSideWrapper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.titleLeftSide}>
          <span>{serviceName}</span>
          <ServicesTitle
            title={serviceTitle}
            scrollPosition={scrollPosition}
            animationStart={animationStart}
            animationEnd={animationEnd}
            isFirstElement={true}
            yAxisStart={"0px"}
          />
          <ServicesTitle
            title={serviceTitle}
            scrollPosition={scrollPosition}
            animationStart={animationStart}
            animationEnd={animationEnd}
            isFirstElement={false}
            yAxisStart={"-110px"}
          />
          <ServicesTitle
            title={serviceTitle}
            scrollPosition={scrollPosition}
            animationStart={animationStart}
            animationEnd={animationEnd}
            isFirstElement={false}
            yAxisStart={"-220px"}
          />
          <ServicesTitle
            title={serviceTitle}
            scrollPosition={scrollPosition}
            animationStart={animationStart}
            animationEnd={animationEnd}
            isFirstElement={false}
            yAxisStart={"-330px"}
          />
        </div>
        <div className={styles.articlesServicesRightSide}>
          <div className={styles.articlesServicesNumbersPositionContainer}>
            <div className={styles.articleServiceTitleAndNumber}>
              <span>{nextArticle}</span>
              <span>{nextArticleTitle}</span>
            </div>

            <div className={styles.articleServiceTitleAndNumber}>
              <span>{lastArticle}</span>
              <span>{lastArticleTitle}</span>
            </div>
          </div>

          <div className={styles.articleServiceDescription}>
            <p>{description}</p>
          </div>

          <CustomButton
            image={arrowRightWhite}
            text={translate("home-readmore")}
            linkPath={`/${link?.toLowerCase()}`}
            buttonMainClass={styles.articleServiceReadMoreBtn}
          />
        </div>
      </div>

      <div className={styles.backgroundCircles}>
        <CircleBlur
          initialOpacity={0}
          animateOpacity={0.13}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.aboutMainCircleBlurGreen}
        />

        <CircleBlur
          initialOpacity={0}
          animateOpacity={0.13}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.aboutMainCircleBlurBlue}
        />
        <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
      </div>
    </div>
  );
};

export default ServiceTitleWrapper;
