import React, { FC } from "react";
import { ModalProps } from "../../Shared/MobileModal/MobileModal";
import OurCustomersMobile from "../OurCustomersMain/OurCustomersMobile";
import HomeAboutMobile from "./HomeAboutMobile/HomeAboutMobile";
import HomeBlogMobile from "./HomeBlogMobile/HomeBlogMobile";
import HomeCaseStudiesMobile from "./HomeCaseStudiesMobile/HomeCaseStudiesMobile";
import HomeServicesMobile from "./HomeServicesMobile/HomeServicesMobile";
import FooterBanner from "../../Shared/FooterBanner/FooterBanner";
import contactBackground from "../../../images/mobile-images/homeBackgroundM.webp";
import HeroBannerMobile from "../../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../../i18n/translate";
import HomeTestimonialMain from "./HomeTestimonialMain/TestimonialMain";

type Props = {
  data: any;
  testimonialindex: number;
};

const HomeMobile: FC<ModalProps & Props> = ({
  isModalHidden,
  setIsModalHidden,
  modalContent,
  data,
  testimonialindex,
}) => {
  return (
    <div>
      <HeroBannerMobile
        homeBackground={contactBackground}
        heading={translate("home-intro")}
        description={translate("home-description")}
        link={"/contact"}
        alt={"Home-hero"}
        buttonText={translate("home-contactus")}
      />
      <HomeAboutMobile />
      <HomeServicesMobile />
      <OurCustomersMobile />
      <HomeTestimonialMain
        data={data.allSanityTestimonial}
        testimonialindex={testimonialindex}
      />
      <HomeCaseStudiesMobile data={data.allSanityCaseStudy} />
      <HomeBlogMobile data={data.allSanityBlog} />
      <FooterBanner
        bottomText={translate("home-stayTuned")}
        bottomParagraph={translate("home-subscribe")}
        FormProp
      />
    </div>
  );
};

export default HomeMobile;
