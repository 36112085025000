// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ServicesMain-module--aboutMainCircleBlurBlue--g+VgZ";
export var aboutMainCircleBlurGreen = "ServicesMain-module--aboutMainCircleBlurGreen--rZlgP";
export var articleServiceDescription = "ServicesMain-module--articleServiceDescription--WqihB";
export var articleServiceReadMoreBtn = "ServicesMain-module--articleServiceReadMoreBtn--kvkja";
export var articleServiceTitleAndNumber = "ServicesMain-module--articleServiceTitleAndNumber--Tt-7Y";
export var articlesButton = "ServicesMain-module--articlesButton--o3m9F";
export var articlesServicesNumbersPositionContainer = "ServicesMain-module--articlesServicesNumbersPositionContainer--nhHT8";
export var articlesServicesRightSide = "ServicesMain-module--articlesServicesRightSide--Ra2mr";
export var backgroundCircles = "ServicesMain-module--backgroundCircles--YvBSE";
export var caseStudiesTitleCircle = "ServicesMain-module--caseStudiesTitleCircle--mbOSr";
export var heroBackgroundCircleLeft = "ServicesMain-module--heroBackgroundCircleLeft--9kCqf";
export var heroWhiteBlurCirce = "ServicesMain-module--heroWhiteBlurCirce--aR5Tt";
export var innerServiceContainer = "ServicesMain-module--innerServiceContainer--symsj";
export var moveDown = "ServicesMain-module--moveDown--yE3Bi";
export var moveDown3 = "ServicesMain-module--moveDown3--OjNaJ";
export var moveDown4 = "ServicesMain-module--moveDown4--YU0dF";
export var serviceContinerDiv = "ServicesMain-module--serviceContinerDiv--t1hzw";
export var servicesHeader2 = "ServicesMain-module--servicesHeader2--YWsZn";
export var servicesHeader3 = "ServicesMain-module--servicesHeader3--CDIN8";
export var servicesHeader4 = "ServicesMain-module--servicesHeader4--BYh4V";
export var servicesLeftAndRightSideWrapper = "ServicesMain-module--servicesLeftAndRightSideWrapper--ycpqf";
export var servicesMainContainer = "ServicesMain-module--servicesMainContainer--aofcb";
export var titleLeftSide = "ServicesMain-module--titleLeftSide--1O8Tr";