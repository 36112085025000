import React from "react";
import * as styles from "./HomeAboutMobile.module.scss";
import yearsAbout from "../../../../images/mobile-images/yearsAbout.webp";
import aboutAws from "../../../../images/mobile-images/aboutAws.webp";
import CircleBlur from "../../../Shared/CircleOutlineAndBlur/CircleBlur";
import translate from "../../../../i18n/translate";

const HomeAboutMobile = () => {
  return (
    <div className={styles.aboutMobileWrapper}>
      <h2>{translate("home-whoweare")}</h2>
      <div className={styles.aboutUsAwsContainer}>
        <div className={styles.aboutUs}>
          <img src={yearsAbout} alt="yearsAbout" />
          <div className={styles.aboutText}>
            <h4>
              <span>.</span>
              {translate("home-4years")}
            </h4>
            <p>{translate("home-yearsDescription")}</p>
          </div>
        </div>
        <div className={styles.aboutAws}>
          <div className={styles.aboutText}>
            <h4 className="heading">
              <span>.</span>100% AWS
            </h4>
            <p>{translate("home-aboutAws")}</p>
          </div>
          <img src={aboutAws} alt="aboutAws" />
        </div>

        <div className={styles.backgroundCircles}>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.5}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
          <CircleBlur
            initialScale={0.1}
            animateScale={1}
            initialOpacity={0}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroWhiteBlurCirce}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutMobile;
