import React, { FC, useEffect, useRef, useState } from "react";
import { UseScrollPosition } from "../../hooks/UseScrollPosition";
import { serviceTitleData } from "../../Shared/HelperFunctions/ServiceTitleHelper";
import ServiceTitleWrapper from "./ServicesHelpers/ServiceTitleWrapper";
import * as styles from "./ServicesMain.module.scss";

const ServicesMain: FC = () => {
  const scrollPosition = UseScrollPosition();
  const serviceContainerRef = useRef(null);
  const innerServiceContainerRef = useRef(null);
  const [top, setTop] = useState(0);
  const [startPoint, setStartPoint] = useState(0);
  const prevScrollRef = useRef(null);
  const animationStartPoint = 0.39;
  const animationStartPoint2 = 0.54;
  const animationStartPoint3 = 0.65;
  const animationStartPoint4 = 2.52;

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  const scrollY = isBrowser ? window.scrollY : "";
  useEffect(() => {
    if (isBrowser) {
      window.onscroll = () => {
        if (window.scrollY > serviceContainerRef.current?.offsetTop) {
          if (!startPoint) {
            setStartPoint(scrollPosition);
          }
        }
        if (scrollPosition > startPoint + animationStartPoint2) {
          setTop(prev =>
            prev - (window.scrollY - prevScrollRef.current) < 0
              ? prev - (window.scrollY - prevScrollRef.current)
              : 0
          );
        } else if (top !== 0) {
          setTop(0);
        }
        prevScrollRef.current = window.scrollY;
      };
    }
  }, [scrollY]);

  const currentService = serviceTitleData({ startPoint, scrollPosition });

  return (
    <div
      className={styles.servicesMainContainer}
      ref={serviceContainerRef}
      style={
        scrollPosition >= startPoint + animationStartPoint
          ? { display: "flex", alignItems: "flex-end" }
          : {}
      }
    >
      <div
        ref={innerServiceContainerRef}
        className={styles.innerServiceContainer}
      ></div>
      <div
        style={{
          position:
            startPoint &&
            scrollPosition >= startPoint &&
            window.scrollY <
              innerServiceContainerRef?.current?.offsetTop +
                serviceContainerRef?.current?.offsetTop
              ? "fixed"
              : "relative",
          top: 0,
          marginBottom:
            startPoint &&
            scrollPosition > startPoint + animationStartPoint3 &&
            window.scrollY > serviceContainerRef.current?.offsetTop
              ? "210px"
              : 0,
          marginTop:
            startPoint &&
            scrollPosition > startPoint + animationStartPoint3 &&
            window.scrollY > serviceContainerRef.current?.offsetTop
              ? "1200px"
              : "0px",
        }}
        className={styles.serviceContinerDiv}
      >
        {scrollPosition < startPoint + animationStartPoint4 && (
          <ServiceTitleWrapper
            scrollPosition={
              scrollY > serviceContainerRef?.current?.offsetTop
                ? scrollPosition
                : 0
            }
            serviceName={currentService.serviceName}
            serviceTitle={currentService.title}
            nextArticle={currentService.nextArticle}
            lastArticle={currentService.lastArticle}
            description={currentService.description}
            nextArticleTitle={currentService.nextArticleTitle}
            lastArticleTitle={currentService.lastArticleTitle}
            animationStart={startPoint + currentService.animationStart}
            animationEnd={startPoint + currentService.animationEnd}
            link={currentService.link}
          />
        )}
      </div>
    </div>
  );
};

export default ServicesMain;
