import React, { useRef, FC } from "react";
import loadable from "@loadable/component";
import * as styles from "../OurCustomersMobileSlider.module.scss";
import greenArrowRightBlog from "../../../images/greenArrowRightBlog.svg";
import arrowGreenLeftBlog from "../../../images/arrowGreenLeftBlog.svg";

type Props = {
  data: any;
};

const Carousel = loadable(() => import("react-tiny-slider"));

const OurCustomersMobileSlider: FC<Props> = ({ data }) => {
  const carouselBlog = useRef(null);

  const goNextSlide = () => carouselBlog.current.goTo("next");
  const goPreviousSlide = () => carouselBlog.current.goTo("prev");
  return (
    <div className={styles.carouselMainDiv}>
      <div className={styles.blogArrowContainer}>
        <img
          src={arrowGreenLeftBlog}
          onClick={() => goPreviousSlide()}
          alt=""
        />
        <img src={greenArrowRightBlog} onClick={() => goNextSlide()} alt="" />
      </div>
      <div className={styles.carouselInnerDiv}>
        <Carousel
          swipeAngle={false}
          items={2}
          mouseDrag
          loop={true}
          gutter={15}
          ref={carouselBlog}
          controls={false}
          nav={false}
          style={{ overflow: "hidden" }}
        >
          {data?.map((item: any, index: number) => {
            return (
              <div key={index} className={styles.carouselDiv}>
                {item?.map((node: any, index1: number) => {
                  return (
                    <div key={index1} className={styles.carouselSubDiv}>
                      <div className={styles.carouselImgDiv}>
                        <img
                          src={node}
                          alt="cloudInsigtsImage"
                          className={styles.carouselImg}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default OurCustomersMobileSlider;
