// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OurCustomersMainSlider-module--PartnerMobileCarousel--3csaE";
export var PartnersCarouselDesc = "OurCustomersMainSlider-module--PartnersCarouselDesc--N2n55";
export var carousel = "OurCustomersMainSlider-module--carousel--RZ1sS";
export var carouselDiv = "OurCustomersMainSlider-module--carouselDiv--3dTc9";
export var carouselImage = "OurCustomersMainSlider-module--carouselImage--uupMo";
export var carouselImageDiv = "OurCustomersMainSlider-module--carouselImageDiv--Eh8AW";
export var carouselImg = "OurCustomersMainSlider-module--carouselImg--V0+L1";
export var carouselImgDiv = "OurCustomersMainSlider-module--carouselImgDiv--y-Yap";
export var carouselMainDiv = "OurCustomersMainSlider-module--carouselMainDiv--Wgfrs";
export var carousel__container = "OurCustomersMainSlider-module--carousel__container--vTndK";
export var carousel__slider = "OurCustomersMainSlider-module--carousel__slider--OXZbf";
export var carousel__sliderTrayWrapHorizontal = "OurCustomersMainSlider-module--carousel__slider-tray-wrap--horizontal--j-uwZ";
export var carousel__sliderTrayWrapper = "OurCustomersMainSlider-module--carousel__slider-tray-wrapper--zLLqp";
export var caseStudiesDescription = "OurCustomersMainSlider-module--caseStudiesDescription--c6uzJ";
export var contactButton = "OurCustomersMainSlider-module--contactButton--ee5Qg";
export var evenSlides = "OurCustomersMainSlider-module--evenSlides--ysKuz";
export var partnersMainSlider = "OurCustomersMainSlider-module--partnersMainSlider--2k7cO";
export var subDiv = "OurCustomersMainSlider-module--subDiv--heB+o";